import React from 'react';

export const Home = function () {
       return (
        <div></div>
    );
}

Home.displayName = Home.name;

