import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePoll from '../hooks/usePoll';

const TicketStatus = { Waiting: 0, Called: 1, AtTheCounter: 2, Finished: 3 };

const timeLeftToString = function (timeLeftSeconds) {
    var hours = Math.floor(timeLeftSeconds / 3600);
    var minutes = Math.floor((timeLeftSeconds - (hours * 3600)) / 60);
    var seconds = timeLeftSeconds - (hours * 3600) - (minutes * 60);
    if (seconds > 0)
        minutes++;

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    return hours + ':' + minutes;
}

export const Ticket = function () {
    let { key } = useParams();
    let [ticket, setTicket] = useState();
    let [lastUpdate, setLastUpdated] = useState();

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(`ticket/${key}`);
            const data = await response.json();
            setTicket(data);
            setLastUpdated(new Date());
        } catch (ex) {
            console.error(ex);
        }
    }, [key]);

    usePoll(async () => {
        await fetchData();
    });

    if (!ticket) {
        return (
            <span></span>
        );
    }

    let { ticketNumber, ticketStatus, serviceName, numberOfCounters, ticketsLeft, timeLeftSeconds, counterLabel } = ticket;
    let timeLeft;
    if (timeLeftSeconds > 0)
        timeLeft = timeLeftToString(timeLeftSeconds);

    return (
        <div className="container ticket">
            <div className="row">
                <div className="col-sm service-name">
                    <div className="label">УСЛУГА</div>
                    <h4>{serviceName}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm ticket-number">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">Номер на билет</h6>
                            <p className="card-text">{ticketNumber}</p>
                        </div>
                    </div>
                </div>
            </div>
            {ticketStatus === TicketStatus.Waiting &&
                <>
                    <div id="numbers" className="row numbers">
                        <div className="col">
                            <div className="label">работещи<br />гишета</div>
                            <div id="counters" className="number">{numberOfCounters}</div>
                        </div>
                        <div className="col middle">
                            <div className="label">общ брой<br />чакащи</div>
                            <div id="ticketsLeft" className="number">{ticketsLeft}</div>
                        </div>
                        <div className="col">
                            <div className="label">очаквано време<br />до повикване</div>
                            <div id="timeLeft" className="number">
                                {timeLeft &&
                                    <>
                                        {timeLeft}<span style={{ fontSize: 'smaller' }}>ч</span>
                                    </>
                                }
                                {!timeLeft && '-'}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm message">
                            <div className="label">ВНИМАНИЕ</div>
                            <div className="message-text">
                                Очакваното време до повикване е динамично!<br />
                                Моля, следете и общия брой чакащи, за да не пропуснете реда си!
                            </div>
                            {lastUpdate &&
                                <div className="last-update">последно обновяване в {lastUpdate.toLocaleTimeString('bg-BG')}</div>
                            }
                        </div>
                    </div>
                </>
            }
            {(ticketStatus === TicketStatus.Called || ticketStatus === TicketStatus.AtTheCounter) &&
                <div id="called" className="row called">
                    <div className="col">
                        Моля, заповядайте<br />на гише <strong>{counterLabel}</strong>
                    </div>
                </div>
            }
        </div>
    );
}

Ticket.displayName = Ticket.name;

