import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import './custom.css'
import { Route, Routes } from 'react-router-dom';
import { Ticket } from './components/Ticket';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/_k/:key' element={<Ticket />} />
            </Routes>
      </Layout>
    );
  }
}
